/* eslint-disable no-shadow */
import React, { useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themesAnimated);
am4core.addLicense('ch-custom-attribution');

function InformationCtrByChart(props) {
  const { chartId, chartData, seriesOne, seriesTwo, seriesThree, seriesFour } =
    props;

  useLayoutEffect(() => {
    const chart = am4core.create(`${chartId}`, am4charts.XYChart);
    chart.responsive.enabled = true;
    chart.data = chartData;
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'orgName';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.tooltipText = '{valueY}';
    const label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 250;
    label.textAlign = 'center';
    chart.colors.list = [
      am4core.color('#008ffb'),
      am4core.color('#00e396'),
      am4core.color('#feb019'),
      am4core.color('#ff4560'),
    ];

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.above = true;
    valueAxis.adjustLabelPrecision = false;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'total';
    series.dataFields.categoryX = 'orgName';
    series.columns.template.tooltipText = ` ${seriesOne}: {valueY}`;
    series.name = seriesOne;
    /* series.columns.template.height = am4core.percent(50);
		series.columns.template.width = am4core.percent(30); */

    const valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = `{valueY}`;
    valueLabel.label.dy = -10;
    valueLabel.label.hideOversized = false;
    valueLabel.label.truncate = false;

    const series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = 'reported';
    series2.dataFields.categoryX = 'orgName';
    series2.columns.template.tooltipText = ` ${seriesTwo}: {valueY}`;
    series2.name = seriesTwo;

    /* series2.columns.template.height = am4core.percent(50);
		series2.columns.template.width = am4core.percent(30); */

    const valueLabel1 = series2.bullets.push(new am4charts.LabelBullet());
    valueLabel1.label.text = `{valueY}`;
    valueLabel1.label.dy = -10;
    valueLabel1.label.hideOversized = false;
    valueLabel1.label.truncate = false;

    const series3 = chart.series.push(new am4charts.ColumnSeries());
    series3.dataFields.valueY = 'expired';
    series3.dataFields.categoryX = 'orgName';
    series3.columns.template.tooltipText = ` ${seriesThree}: {valueY}`;
    series3.name = seriesThree;
    /* series3.columns.template.height = am4core.percent(50);
		series3.columns.template.width = am4core.percent(30); */

    const valueLabel2 = series3.bullets.push(new am4charts.LabelBullet());
    valueLabel2.label.text = `{valueY}`;
    valueLabel2.label.dy = +10;
    valueLabel2.label.hideOversized = false;
    valueLabel2.label.truncate = false;

    const series4 = chart.series.push(new am4charts.ColumnSeries());
    series4.dataFields.valueY = 'unreported';
    series4.dataFields.categoryX = 'orgName';
    series4.columns.template.tooltipText = ` ${seriesFour}: {valueY}`;
    series4.name = seriesFour;
    /* series4.columns.template.height = am4core.percent(50);
		series4.columns.template.width = am4core.percent(30); */

    const valueLabel3 = series4.bullets.push(new am4charts.LabelBullet());
    valueLabel3.label.text = `{valueY}`;
    valueLabel3.label.dy = +10;
    valueLabel3.label.hideOversized = false;
    valueLabel3.label.truncate = false;

    chart.legend = new am4charts.Legend();
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.start = chartData.length > 6 ? 10 : 0;
    chart.scrollbarX.thumb.minWidth = 450;
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    // chart.scrollbarX.startGrip.disabled = true;
    // chart.scrollbarX.endGrip.disabled = true;
    // chart.scrollbarX.height = 40
    // chart.scrollbarX.paddingBottom = 30;
    // chart.scrollbarX.start = 1

    chart.legend.useDefaultMarker = true;

    // const scrollbarX = new am4charts.XYChartScrollbar();
    // chart.scrollbarX.parent = chart.bottomAxesContainer;
  }, [chartData, chartId]);

  return (
    <div
      id={chartId}
      style={{
        width: '100%',
        height: '470px',
        display: 'flex',
        overflow: 'hidden',
        minWidth: 900,
      }}
    />
  );
}
export default InformationCtrByChart;

